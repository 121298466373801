import React from "react";
import {ctaEvent} from "../../../utils/events";

export async function getStaticProps(context) {
    return {
        props: {}, // will be passed to the page component as props
    }
}

export default function Header() {

    return (
        <>
            <div className="header-container hero">
                <div className="row">
                    <div className="col-md-12">
                        <h1>EXTEND YOUR TAX RETURN<br/>THROUGH <span>OCTOBER 15th</span></h1>
                        <p>An IRS Tax Extension Gives you More Time for Your Taxes</p>
                        <a className="btn btn-default" onClick={() => ctaEvent("hero-cta")} href="/submit-extension">Submit Tax Extension</a>
                    </div>
                </div>
            </div>
        </>
    )
}
